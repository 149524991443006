import Image from "next/image";
import { ReactElement } from "react";
import buildingTypes from "./buildingTypes";
import styles from "./styles.module.scss";

export default function BuildingTypes(): ReactElement {
    return (
        <div className={`${styles.buildingTypes} py-10 md:py-32`}>
            <div className={`flex flex-col mx-auto px-4vw lg:px-4  `}>
                <h2 className="font-bold mb-4  mx-auto md:mb-6 text-2xl text-center  md:text-4xl ">
                    What Are the Different Types of Steel Buildings?
                </h2>
                <p className="mb-6  mx-auto md:mb-10 text-lg text-center  md:text-2xl ">
                    Residential:
                </p>
                <Grid list={buildingTypes.residential} />
                <p className="mt-10 mb-6  mx-auto md:mb-10 text-lg text-center  md:text-2xl ">
                    Commercial & Industrial:
                </p>
                <Grid list={buildingTypes.commercial} />
            </div>
        </div>
    );
}

function Grid({ list }: { list: { path: string; title: string }[] }) {
    return (
        <div className="grid grid-cols-2 place-items-center md:grid-cols-4  text-center gap-16">
            {list.map((item) => (
                <div
                    className="flex flex-col justify-center items-center"
                    key={item.title}
                >
                    <div className="relative w-[150px] md:w-[200px] h-[150px] md:h-[200px] ">
                        <Image
                            layout="fill"
                            src={`/steelbuildingestimates/assets/images/${item.path}.png`}
                            alt={item.title}
                        />
                    </div>
                    <p className="text-center mt-6 text-gray-500 font-light">
                        {item.title}
                    </p>
                </div>
            ))}
        </div>
    );
}
