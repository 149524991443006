const buildingTypes = {
    residential: [
        {
            path: "garagesOpt",
            title: "Garages",
        },
        {
            path: "aircraft_hangersOpt",
            title: "Aircraft Hangars",
        },
        {
            path: "carportsOpt",
            title: "Carports",
        },
        {
            path: "workshopsOpt",
            title: "Workshops",
        },
        {
            path: "stables_and_arenasOpt",
            title: "Horse Stables & Arenas",
        },
        {
            path: "barnsOpt",
            title: "Barns",
        },
        {
            path: "steel_homesOpt",
            title: "Steel Homes",
        },
        {
            path: "farm_buildingsOpt",
            title: "Farm Buildings",
        },
    ],
    commercial: [
        {
            path: "auto_dealershipsOpt",
            title: "Auto Dealerships",
        },
        {
            path: "officesOpt",
            title: "Offices",
        },
        {
            path: "self_storagesOpt",
            title: "Self Storages",
        },
        {
            title: "Recreational Buildings & Covers",
            path: "coversOpt",
        },
    ],
};

export default buildingTypes;
